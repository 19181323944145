
<template>
  <modal name="change-pass-user-modal" @before-open="beforeOpen" @closed="clear" :adaptive="true" height="auto" :maxWidth="438"
         class="modal-change-pass modal">
    <h3 class="modal-title">Attention!</h3>
    <p class="modal-text">You try to change password on user.</p>
    <form @submit.prevent="submit" class="modal-change-pass-form" autocomplete="off">
      <Input label="Login" readonly :value="selectedUser && selectedUser.email"/>
      <Input label="New Password" :error="$v.password.$error ? 'Required' : null" type="password"  v-model.trim="$v.password.$model"/>
      <button class="btn blue" type="button" :disabled="$v.$invalid" @click="copy">Copy Info</button>
      <button class="btn purple" :disabled="$v.$invalid" type="submit">Change Password</button>
    </form>
  </modal>
</template>

<script>
import {required} from 'vuelidate/lib/validators';
import {mapActions, mapGetters, mapState} from "vuex";
import modal from "@/mixins/modal";
import {validationMixin} from 'vuelidate';

export default {
  name: "UserChangePassModal",
  components: {
    Input: () => import('@/components/common/AInput')
  },
  mixins: [modal, validationMixin],
  data() {
    return {
      password: '',
    }
  },
  validations: {
    password: {
      required
    }
  },
  computed: {
    ...mapGetters(['selectedUser']),
  },
  methods: {
    ...mapActions(['setTempUser', 'changeUserPass']),
    async submit() {
      await this.changeUserPass({
        id: this.selectedUser.id,
        password: this.password
      }).then(() => {
        this.$notify({
          title: 'Change user password',
          text: 'Password changed successfully',
          type: 'success'
        });
        this.$modal.hide('change-pass-user-modal');
        this.clear();
      }).catch(error => {
        console.error('Error changing user password:', error);
        this.$notify({
          title: 'Change user password',
          text: error.response.data.message,
          type: 'error'
        });
      });
    },
    clear() {
      this.setTempUser(null);
      this.modalActive = false;
      this.password = '';
      this.$v.$reset();
    },
    copy(){
      navigator.clipboard.writeText(`${window.location.origin}/login\nLogin: ${this.selectedUser.email}\nPassword: ${this.password}`).then(() => {
        this.$notify({
          title: 'Copy Info',
          text: 'Info copied successfully!',
          type: 'success'
        });
      }).catch(error => {
        console.error('Error copy:', error);
        this.$notify({
          title: 'Copy Info',
          text: 'Sorry, unable to copy to clipboard',
          type: 'error'
        });
      });
    }
  },
}
</script>

<style scoped>

</style>
